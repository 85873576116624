.hhycBg .noUi-connect {
    background:  #e64484 !important;
}
.hhycBg .noUi-handle.noUi-handle-lower, .hhycBg .noUi-handle.noUi-handle-upper {
    background:  #e64484 !important;
}
.cDNlyh {
    color:  #e64484 !important;
}
.dTUCgK {
    background:  #e64484 !important;
}
.fdYPSK {
    border: 1px solid #e64484 !important;
    color: #e64484 !important;
}
.dTUCgK {
    background:  #e64484 !important;
}
.huhXbH {
    background:  #e64484 !important;
    color: #fff !important;
}
.hmxkiK {
    color: #e64484 !important;
}
.ijJdXM,
.ijJdXN {
    background:  #e64484 !important;
}
.bsfzwJ {border: 1px solid rgb(239, 203, 192);box-shadow: rgba(0, 0, 0, 0.1) 5px 5p;}
.gHVTXM:hover {background: rgb(247, 232, 219);border: 1px solid rgb(247, 232, 219);}
.sc-hBURRC:before {
    content: "Выберите услугу или услуги:";
    font-size: 17px;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: 20px;
    font-weight: 500;
    color: red;
    font-size: 20px;
}
table.sc-cxpRKc.PwmVY p {
    color: red;
}
table.sc-cxpRKc.PwmVY tr:nth-child(1) td:last-child div:after {
    content: "Выберите дату и время:";
    clear: both;
    font-size: 20px;
    padding-bottom: 20px;
    display: flex;
    color: red;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-around;
}
table.sc-cxpRKc.PwmVY tr:first-child td:last-child {
    vertical-align: middle;
}
input.form-control.search-service {
    margin-top: 20px;
}