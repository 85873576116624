.checkBox{
    -ms-user-select: none;
  
              /**
               * Для мозилы
               */
              -moz-user-select: none;
  
              /**
               * Для конкверора
               */
              -khtml-user-select: none;
  
              /**
               * Для Сафари и Хрома
               */
              -webkit-user-select: none;
  }
  .checkBox small{
    margin-left: 10px;
    padding-top: 2px;
    font-size: 12px;
    transition: .3s;
  }
  .checkBox.selected small{
    color: #db9a63 !important;
  }
  .check {
      cursor: pointer;
      position: relative;
      margin: auto;
      width: 18px;
      height: 18px;
      -webkit-tap-highlight-color: transparent;
      transform: translate3d(0, 0, 0);
      
    }
    
    .check svg {
      position: relative;
      z-index: 1;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #c8ccd4;
      stroke-width: 1.5;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease;
    }
    .check svg path {
      stroke-dasharray: 60;
      stroke-dashoffset: 0;
      transition: all 0.3s linear;
    }
    .check svg polyline {
      stroke-dasharray: 22;
      stroke-dashoffset: 66;
      transition: all 0.3s linear;
    }
   
    .checkBox:hover svg {
      stroke: #db9a63;
      transition: all 0.3s linear;
    }
    #cbx:checked + .check svg {
      stroke: #db9a63;
    }
    #cbx:checked + .check svg path {
      stroke-dashoffset: 60;
      transition: all 0.3s linear;
    }
    #cbx:checked + .check svg polyline {
      stroke-dashoffset: 42;
      transition: all 0.2s linear;
      transition-delay: 0.15s;
    }